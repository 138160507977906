import { TextDirection, WidgetConfiguration } from 'types';
import { DEPLOY_ENV, WIDGET_SLOT_SELECTOR } from 'utils/constants';
import domConfigProvider from './domConfigProvider';
import { isRunningInIFrame, isRunningOnDiscoPage } from './runtimeDetection';
import { AppConfig } from './types';

export function injectStylesIntoLinkTag(
  url: string,
  root: ShadowRoot | Element,
) {
  if (!url || !root) {
    return;
  }

  const currentLinkTags = Array.from(root.querySelectorAll('link'));
  const styleAlreadyInjected = currentLinkTags.find((tag) => tag.href === url);

  if (styleAlreadyInjected) return;

  const linkTag = document.createElement('link');
  linkTag.rel = 'stylesheet';
  linkTag.href = url;

  root.appendChild(linkTag);
}

export function getInjectionPoints(
  elementOrSelector?: string | HTMLElement,
): HTMLElement[] | undefined {
  if (elementOrSelector instanceof HTMLElement) {
    return [elementOrSelector];
  }

  const selector = elementOrSelector || WIDGET_SLOT_SELECTOR;

  // WIDGET_SLOT_SELECTOR is populated by a variable in process.env, which means
  // it is possibly undefined. While this shouldn't happen in practice, the check
  // is here mostly to remove TS errors.
  if (selector) {
    return Array.from(document.querySelectorAll(selector));
  }

  return undefined;
}

export async function getWidgetConfig(
  injectionPoint: Element,
  overrides?: Partial<WidgetConfiguration>,
): Promise<Partial<WidgetConfiguration>> {
  // only force DOM config when running on a publisher page - they might have query
  // params that conflict with ours, so URL config isn't supported.  We know
  // widget is running on a publisher page if it's not running on a disco page
  if (
    DEPLOY_ENV === 'production' &&
    !isRunningOnDiscoPage() &&
    // don't use domConfig when running in iframe - dom won't contain config variables.
    // this is just a safety net - if the app isn't running on a Disco page, it probably
    // isn't running in an iframe since iframes load Disco pages
    !isRunningInIFrame()
  ) {
    return {
      ...domConfigProvider(injectionPoint),
      ...overrides,
    };
  }

  const [urlConfigProvider, iframeConfigProvider] = await Promise.all([
    import(/* webpackChunkName: "url-config-provider" */ './urlConfigProvider'),
    import(
      /* webpackChunkName: "iframe-config-provider" */ './iframeConfigProvider'
    ),
  ]).then(([url, iframe]) => [url.default, iframe.default]);

  const domConfig = domConfigProvider(injectionPoint);
  const urlConfig = urlConfigProvider(injectionPoint);
  const iframeConfig = iframeConfigProvider(injectionPoint);

  // the configuration below ignores the DOM config - see comment above about the DOM
  // config not being useful inside of an iframe.  this is because the assumption is that
  // when in an iframe, Disco will be running on a "Disco Page" (one of the HTML files in
  // this application).
  //
  // In practice, this isn't always the case, and if we're not on a Disco page, the
  // DOM config might be useful.  for example, the publisher might load us using the
  // DOM configuration within an iframe on a custom page of theirs - in which case we
  // don't want to ignore the DOM config.
  //
  // only ignore dom config if running in an iframe on a disco page
  if (isRunningInIFrame() && isRunningOnDiscoPage()) {
    return {
      ...iframeConfig,
      ...urlConfig,
      ...overrides,
    };
  }

  return {
    ...iframeConfig,
    ...domConfig,
    ...urlConfig,
    ...overrides,
  };
}

// TODO can probably allow mockapi when running in iframe and parent page is a test page,
// but no one is really using the mock api so no motivation to implement that right now
export async function getAppConfig(): Promise<AppConfig> {
  // in production, don't initialize the mock API if the app is running on a publisher
  // page (!isRunningOnDiscoPage()) or it's running in an iframe (isRunningInIFrame())
  if (
    DEPLOY_ENV === 'production' &&
    (!isRunningOnDiscoPage() || isRunningInIFrame())
  ) {
    return { mockApi: false };
  }

  const getAppUrlConfig = (
    await import(
      /* webpackChunkName: "url-config-provider" */ './urlConfigProvider'
    )
  ).getAppConfig;

  const defaultConfig = {
    mockApi:
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_MOCK_API === 'true' ||
      (isRunningOnDiscoPage() && !isRunningInIFrame()),
  };

  return { ...defaultConfig, ...getAppUrlConfig() };
}

/*
 * When webpack installs a style it calls addStylesToDom.  This call happens exactly
 * once for each style.  When Disco injection points are added to the page dynamically
 * (e.g. SPA), they might not have existed when webpack initially installed the file
 * and since webpack won't try to install the file again, we need to do this manually
 * when a new instance of the widget is setup
 */
export function injectInstalledStyles(root: Element) {
  const installedStyles = window?.disco?._installedStyles;
  const addStyleElement = window?.disco?._addStyleElement;

  if (!installedStyles || installedStyles.length === 0 || !addStyleElement) {
    return;
  }

  installedStyles.forEach((element) => {
    addStyleElement(element, root);
  });
}

export function injectTextDirection(
  dir: TextDirection | undefined = 'ltr',
  shadowDomRoot: HTMLElement,
) {
  // set "dir" on the html element.  this is unsafe if not running on a Disco
  // page.  we don't want to override the publisher's prop
  if (isRunningOnDiscoPage() && document.dir !== dir) {
    document.dir = dir;
  }

  // safari and firefox can't use :host-context css selector, which is what's used
  // to reverse text direction on rtl sites.  they can only use :host, so
  // also write the direction to the shadow dom root
  shadowDomRoot.dir = dir;
}

export * from './runtimeDetection';
