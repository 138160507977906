import { BASE_URL } from './constants';

export async function createMockApi(force?: boolean) {
  if (process.env.REACT_APP_MOCK_API === 'true' || force) {
    const { worker } = await import(
      /* webpackChunkName: "browsermock" */ 'mocks/browser'
    );

    if (process.env.NODE_ENV === 'development') {
      worker.start();
    } else {
      worker.start({
        serviceWorker: {
          url: `${BASE_URL}mockServiceWorker.js`,
        },
      });
    }
  }
}
